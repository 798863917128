<template>
  <b-row class="clearfix footer-tracebit mb-0 mt-2 mx-0 w-100">
    <b-col
      lg="12"
      class="my-3"
    >
      <div class="text-center">
        <p style="margin-bottom: 5px;">
          Powered by
        </p>
        <b-link
          class="brand-logo"
          to="/"
        >
          <img
            :src="require('@/assets/images/logo/TraceBit-logo.svg')"
            alt="Logo"
            height="25"
          >
        </b-link>
        <p class="privacy_term">
          <b-link :to="{name:'privacy-policy'}">Privacy policy. </b-link>
          <b-link :to="{name:'term-condition'}">Terms and Conditions</b-link>
        </p>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import { BLink, BRow, BCol } from 'bootstrap-vue'

export default {
  components: {
    BLink,
    BRow,
    BCol,
  },
}
</script>
<style>
.footer-tracebit {
  text-align: center;
  display: flex;
  justify-content: center;
  left: 0;
  right: 0;
  bottom: 0;
}
.privacy_term {
  padding-top: 1rem;
}
.privacy_term a {
  color: black;
}
.privacy_term a:hover {
  color: #1b4694;
}
</style>
