<template>
  <div v-if="landingPageData.id !== null">
    <div
      v-if="userData !== null"
      class="demo-spacing-0"
    >
      <b-alert
        variant="warning"
        show
      >
        <div class="alert-body">
          <span>During landing page preview, all form submission <strong>will not be recorded.</strong></span>
        </div>
      </b-alert>
    </div>
    <div
      v-if="landingPageData.thank_you !== null || landingPageData.thank_you !== undefined"
      :show="overlay"
      variant="light"
      :opacity="0.7"
      blur="2px"
      rounded="sm"
      style="height: 80%;"
    >
      <div
        id="thankyou-page"
        class="h-100 d-flex flex-column align-items-center"
      >
        <div
          class="text-center card-icon position-top-0"
          style="height: fit-content;"
        >
          <b-img
            v-show="landingPageData.picture_link !== undefined"
            :src="landingPageData.picture_link"
            style="width: 100%; object-fit: contain; padding: 2rem 1rem;"
          />
        </div>
        <div class="thankyou-page__content text-center d-flex flex-column align-items-center justify-content-center">
          <div class="mt-3 d-flex flex-column align-items-center justify-content-center">
            <p
              class="font-large-2 font-weight-bold"
              style="line-height: normal"
            >
              {{ landingPageData.thank_you.title }}
            </p>
            <p class="mt-3 font-medium-2 font-weight-bold" v-html="landingPageData.thank_you.body">
            </p>
            <div
              v-if="landingPageData.thank_you.offer_download_file === true"
            >
              <div class="mt-2">
                <div>
                  <b-button
                    v-if="landingPageData.thank_you.cta_file !== null || landingPageData.thank_you.cta_file !== ''"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    pill
                    :href="landingPageData.thank_you.cta_file"
                    target="_blank"
                  >
                    {{ landingPageData.thank_you.cta_label }}
                  </b-button>
                  <b-button
                    v-else-if="landingPageData.thank_you.cta_url !== null || landingPageData.thank_you.cta_url !== ''"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    pill
                    :href="landingPageData.thank_you.cta_url"
                    target="_blank"
                  >
                    {{ landingPageData.thank_you.cta_label }}
                  </b-button>
                </div>
              </div>
              <div class="marketplace-links mt-5">
                <template
                  v-for="(item, index) in landingPageData.url_link"
                >
                  <div
                    :key="index"
                    cols="12"
                    class="mt-1 mx-1"
                  >
                    <b-button
                      block
                      variant="outline-secondary"
                      pill
                      :href="item"
                      target="_blank"
                      :style="{
                        color: landingPageData.color_submit_button + ' !important',
                        backgroundColor: 'transparent !important',
                        borderColor: landingPageData.color_submit_button + ' !important',
                      }"
                    >
                      {{ landingPageData.text_link[index] }}
                    </b-button>
                  </div>
                </template>
              </div>
              <div class="mt-5">
                <div
                  class="social-media-link"
                  style="font-size: 20px; width: max-content; margin: auto"
                >
                  <b-link
                    v-if="landingPageData.instagram !== null"
                    :href="landingPageData.instagram"
                    target="_blank"
                    class="p-1"
                  >
                    <i class="bi bi-instagram" />
                  </b-link>
                  <b-link
                    v-if="landingPageData.facebook !== null"
                    :href="landingPageData.facebook"
                    target="_blank"
                    class="p-1"
                  >
                    <i class="bi bi-facebook" />
                  </b-link>
                  <b-link
                    v-if="landingPageData.linkedin !== null"
                    :href="landingPageData.linkedin"
                    target="_blank"
                    class="p-1"
                  >
                    <i class="bi bi-linkedin" />
                  </b-link>
                  <b-link
                    v-if="landingPageData.youtube !== null"
                    :href="landingPageData.youtube"
                    target="_blank"
                    class="p-1"
                  >
                    <i class="bi bi-youtube" />
                  </b-link>
                  <b-link
                    v-if="landingPageData.tiktok !== null"
                    :href="landingPageData.tiktok"
                    target="_blank"
                    class="p-1"
                  >
                    <i class="bi bi-tiktok" />
                  </b-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-else
      class="demo-spacing-0"
    >
      <b-alert
        variant="danger"
        show
      >
        <div class="alert-body text-center">
          <span>This Thank You Page <strong>is under maintenance</strong></span>
        </div>
      </b-alert>
    </div>

    <!-- footer -->
    <Footer />
  </div>

</template>

<script>
import store from '@/store'
import router from '@/router'
import { required, email } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BButton, BImg, BAlert, BLink,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Footer from '@/views/menus/Footer.vue'

export default {
  components: {
    Footer,
    BButton,
    BImg,
    BAlert,
    BLink,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      disableBtn: false,
      overlay: false,
      formSucceded: false,
      formStore: {},
      required,
      email,
      landingPageData: {
        id: null,
        picture_link: null,
        description: '',
        body_description: '',
        aggreement: '',
        expired_finish_date: '',
        expired_start_date: '',
        label_submit_button: '',
        color_submit_button: '',
        title: null,
        form_id: null,
        text_link: [],
        color_link: [],
        url_link: [],
        marketing_link: '',
        instagram: '',
        facebook: '',
        linkedin: '',
        youtube: '',
        tiktok: '',
      },
      formId: null,
      formData: {
        landing_page_uuid: null,
        landing_page_query: this.$route.query,
      },
      submitButton: {
        label: '',
        color: '',
      },
      userData: null,
    }
  },
  computed: {
  },
  created() {
    this.getLandingPageForm()
  },
  methods: {
    getLandingPageForm() {
      this.overlay = true
      if (router.currentRoute.params.uuid !== undefined){
        store.dispatch('menu-landing-page/getLandingPageFormByUuid', {
          uuid: router.currentRoute.params.uuid,
        })
          .then(response => {
            // resolve Landing Page data
            this.landingPageData = response.data.data
            this.landingPageData.text_link = JSON.parse(this.landingPageData.text_link)
            this.landingPageData.color_link = JSON.parse(this.landingPageData.color_link)
            this.landingPageData.url_link = JSON.parse(this.landingPageData.url_link)
            this.formId = this.landingPageData.form_id
            this.submitButton.label = this.landingPageData.label_submit_button
            this.submitButton.color = this.landingPageData.color_submit_button
            if (this.landingPageData.thank_you.offer_download_file === 1){
              this.landingPageData.thank_you.offer_download_file = true
            } else {
              this.landingPageData.thank_you.offer_download_file = false
            }
            // check query param channel or no query
            const uri = window.location.search.substring(1)
            const params = new URLSearchParams(uri)
            if (uri === ''){
              this.overlay = false
            } else if (params.get('channel')){ // check if query param inside marketing channels
              const marketingLinkParams = JSON.parse(this.landingPageData.marketing_link.param)
              if (marketingLinkParams.includes(params.get('channel'))){
                this.overlay = false
              }
            }
          })
      } else if (router.currentRoute.params.slug !== undefined){
        store.dispatch('menu-landing-page/getLandingPageFormBySlug', {
          slug: router.currentRoute.params.slug,
        })
          .then(response => {
            // resolve Landing Page data
            this.landingPageData = response.data.data
            this.landingPageData.text_link = JSON.parse(this.landingPageData.text_link)
            this.landingPageData.color_link = JSON.parse(this.landingPageData.color_link)
            this.landingPageData.url_link = JSON.parse(this.landingPageData.url_link)
            this.formId = this.landingPageData.form_id
            this.submitButton.label = this.landingPageData.label_submit_button
            this.submitButton.color = this.landingPageData.color_submit_button
            if (this.landingPageData.thank_you.offer_download_file === 1){
              this.landingPageData.thank_you.offer_download_file = true
            } else {
              this.landingPageData.thank_you.offer_download_file = false
            }
            // check query param channel or no query
            const uri = window.location.search.substring(1)
            const params = new URLSearchParams(uri)
            if (uri === ''){
              this.overlay = false
            } else if (params.get('channel')){ // check if query param inside marketing channels
              const marketingLinkParams = JSON.parse(this.landingPageData.marketing_link.param)
              if (marketingLinkParams.includes(params.get('channel'))){
                this.overlay = false
              }
            }
          })
      }
    },
    getSelf(){
      store.dispatch('app-user/getSelf')
        .then(response => {
          this.userData = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    getErrorToast(message){
      return this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: 'Invalid to access Landing Page',
          icon: 'XIcon',
          variant: 'danger',
          text: message,
        },
      })
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/pages/page-auth.scss';
  .thankyou-page__content {
    width: 75% !important;
  }
  .thankyou-page__content > div {
    min-height: 50vh;
  }
  .social-media-link a {
    color: grey;
  }
  .social-media-link a:hover {
    color: #1b4694;
  }
  .content {
    height: 100%;
    text-align: center;
  }
  .content p {
    display: inline-block;
    vertical-align: middle;
  }
  .marketplace-links {
    display: flex;
  }
  @media (max-width: 500px) {
    .thankyou-page__content {
      width: 90% !important;
    }
    .marketplace-links {
      display: block;
    }
  }
</style>
